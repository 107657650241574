import {FC, PropsWithChildren, ReactNode} from 'react';

import {ButtonShapes} from '~/modules/Appearance/types';
import {PortalSpinner} from './PortalSpinner';

const shapeClass = {
  [ButtonShapes.circular]: 'rounded-full',
  [ButtonShapes.square]: 'rounded-none',
  [ButtonShapes.rounded]: 'rounded-md',
};
const colors = {
  expert: `bg-expertPrimary text-contrast !font-bold`,
  secondary: `bg-transparent border-black border-solid border-[1px]`,
  purple: `bg-brand800 text-contrast border-brand800 border-solid border-[1px]`,
  dark: 'bg-codGray text-white border-black border-solid border-[1px]',
  white:
    'bg-transparent text-codGray border-transparent border-solid border-[1px]',
  light: 'bg-white text-codGray border',
};
const sizeClassName = {
  xs: 'py-2 px-2 text-fine',
  sm: 'py-2 px-4 text-sm',
  md: 'py-3 px-6 text-sm',
  lg: 'py-4 px-8 text-lg',
};

export const Button: FC<
  PropsWithChildren<{
    className?: string;
    color?: 'expert' | 'dark' | 'light' | 'white' | 'purple' | 'secondary';
    fullSized?: boolean;
    icon?: ReactNode;
    endIcon?: ReactNode;
    isLoading?: boolean;
    showLoadingText?: boolean;
    padding?: string;
    shape?: ButtonShapes;
    textColor?: string;
    backgroundColor?: string;
    [key: string]: any;
  }>
> = ({
  className = '',
  color = 'dark',
  fullSized = false,
  children,
  isLoading = false,
  showLoadingText = true,
  icon = null,
  endIcon = null,
  padding = '',
  shape,
  textColor,
  backgroundColor,
  ...props
}) => {
  const Component = props.href ? 'a' : 'button';

  return (
    <Component
      className={` ${colors[color] || colors.dark} ${
        sizeClassName[props.size] || sizeClassName.md
      } ${
        fullSized ? 'w-full' : ''
      } group h-min center-row justify-center text-center focus:z-10 font-medium ${
        shapeClass[shape] || 'rounded-md'
      } ${
        icon || endIcon ? 'gap-2' : ''
      } hover:!opacity-80 focus:!opacity-80 disabled:opacity-70 ${className} `}
      style={{
        ...(textColor ? {color: textColor} : {}),
        ...(backgroundColor ? {backgroundColor} : {}),
      }}
      {...props}
    >
      {children && (
        <>
          {icon}
          {isLoading && (
            <div className={`center-row justify-center gap-2`}>
              <PortalSpinner size="sm" />
              {showLoadingText && <span>Loading...</span>}
            </div>
          )}
          {!isLoading && children}
          {endIcon}
        </>
      )}
      {!children && (
        <div>
          {icon}
          {endIcon}
        </div>
      )}
    </Component>
  );
};
