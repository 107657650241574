import {FC} from 'react';
import {Spinner} from 'flowbite-react';

interface PortalSpinnerProps {
  size?: 'sm' | 'md' | 'lg';
}

export const PortalSpinner: FC<PortalSpinnerProps> = ({size = 'md'}) => {
  return (
    <Spinner
      size={size}
      light={true}
      theme={{
        base: ' text-white animate-spin',
        color: {
          expert: 'fill-codGray700',
        },
      }}
      color="expert"
    />
  );
};
